import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Oakland from "./images/oakland.jpg";
import Emeryville from "./images/sf.png";
import Hayward from "./images/hayward.jpg";

import Languages from "./languages";

import { AssignmentLateSharp } from "@material-ui/icons";
import { List, ListItem } from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const BACSCheckbox = withStyles({
	root: {
		color: "#ff8329",
		"&$checked": {
			color: "#ff8329",
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
	option: {
		fontSize: 15,
		"& > span": {
			marginRight: 10,
			fontSize: 18,
		},
	},
});

const validateDate = (testdate) => {
	//var date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
	//return date_regex.test(testdate);
	var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
	return date_regex.test(testdate);
};

// import atlas from 'azure-maps-rest'
// // Get an Azure Maps key at https://azure.com/maps.
// var subscriptionKey = 'FKNIAeFQ1u2emlgfmjchT8CLrBLesFNwhQq3muDkP_Y';

// // Use SubscriptionKeyCredential with a subscription key.
// var subscriptionKeyCredential = new atlas.SubscriptionKeyCredential(subscriptionKey);

// // Use subscriptionKeyCredential to create a pipeline.
// var pipeline = atlas.MapsURL.newPipeline(subscriptionKeyCredential, {
//   retryOptions: { maxTries: 4 } // Retry options
// });

// // Create an instance of the SearchURL client.
// var searchURL = new atlas.SearchURL(pipeline);

const raceChoices = [
	"American Indian or Alaska Native",
	"Asian",
	"Black or African American",
	"Native Hawaiian or Other Pacific Islander",
	"White",
	"Other Multi-Racial",
	"I’d prefer not to say",
];

const ethnicityChoices = [
	"American Indian/Native American",
	"Indigenous from Mexico, the Caribbean, Central America or South America",
	"Other Indigenous",
	"Chinese",
	"Filipino",
	"Japanese",
	"Korean",
	"Mongolian",
	"Central Asian",
	"South Asian",
	"Southeast Asian",
	"Other Asian",
	"Caribbean",
	"Central American",
	"Mexican",
	"South American",
	"Other Latino",
	"African",
	"African American",
	"Caribbean, Central American, South American or Mexican",
	"Other Black",
	"North African",
	"West Asian",
	"Other Middle Eastern or North African",
	"Chamorro",
	"Native Hawaiian",
	"Samoan",
	"Other Pacific Islander",
	"European",
	"Other White",
	"I’d prefer not to say",
];

const ethnicityObject = {
	indigenous: {
		label: "Indigenous/American Indian",
		selected: [],
		values: [
			"American Indian/Native American",
			"Indigenous from Mexico, the Caribbean, Central America or South America",
			"Other Indigenous",
		],
	},
	asian: {
		label: "Asian",
		selected: [],
		values: [
			"Chinese",
			"Filipino",
			"Japanese",
			"Korean",
			"Mongolian",
			"Central Asian",
			"South Asian",
			"Southeast Asian",
			"Other Asian",
		],
	},
	latino: {
		label: "Latino/a/x",
		selected: [],
		values: ["Latino Caribbean", "Central American", "Mexican", "South American", "Other Latino"],
	},
	black: {
		label: "Black",
		selected: [],
		values: [
			"African",
			"African American",
			"Black Caribbean, Central American, South American or Mexican",
			"Other Black",
		],
	},
	middleEastern: {
		label: "Middle Eastern/West Asian or North African",
		selected: [],
		values: ["North African", "West Asian", "Other Middle Eastern or North African"],
	},
	pacificIslander: {
		label: "Pacific Islander or Native Hawaiian",
		selected: [],
		values: ["Chamorro", "Native Hawaiian", "Samoan", "Other Pacific Islander"],
	},
	white: {
		label: "White",
		selected: [],
		values: ["European", "Other White"],
	},
	other: {
		label: "Decline to State",
		selected: [],
		values: ["I’d prefer not to say"],
	},
};

const genderIdentityChoices = [
	"Female",
	"Male",
	"Genderqueer/Gender Non-Binary",
	"Trans Female",
	"Trans Male",
	"Not Listed",
	"Decline to Answer",
];

const preferredPronounChoices = ["She/Her/Hers", "They/Them/Theirs", "He/Him/His", "Not Listed"];

const sexualOrientationChoices = [
	"Bisexual",
	"Gay/Lesbian/Same-Gender Loving",
	"Questioning/Unsure",
	"Straight/Heterosexual",
	"Not Listed",
	"Decline to Answer",
];

const bestTimeToContactOptions = {
	Times: {
		label:
			"When is the best time to reach you during the week (Monday - Friday)?  Choose all that apply",
		selected: [],
		values: ["Morning", "Afternoon", "Evening", "Anytime"],
	},
};

const whatCausedCrisisOptions = {
	options: {
		label: "What caused your housing crisis? Mark all that apply",
		selected: [],
		values: [
			// "None",
			"Loss of job / reduction in hours",
			"Landlord raised rent",
			"COVID-19 (illness or other impact of pandemic)",
			"Other medical reasons, including increased expenses due to medical costs",
			"Loss of childcare or increased expenditures due to childcare",
			"Mental health or substance use issues",
			"Divorce, separation, or household/roommate conflict",
			"Incarceration",
			"Aging out of foster care",
			"Other",
		],
	},
};

const formatUSNumber = (entry = "") => {
	if (!entry || entry.length < 1) return entry;
	try {
		const match = entry
			.replace(/\D+/g, "")
			.replace(/^1/, "")
			.match(/([^\d]*\d[^\d]*){1,10}$/)[0];
		const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
		const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : "";
		const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : "";
		return `${part1}${part2}${part3}`;
	} catch (e) {
		return entry;
	}
};

export default function AddressForm(data) {
	const classes = useStyles();
	const findAddress = async (input) => {
		const subscriptionKey = "FKNIAeFQ1u2emlgfmjchT8CLrBLesFNwhQq3muDkP_Y";

		fetch(
			`https://atlas.microsoft.com/search/address/json?subscription-key=FKNIAeFQ1u2emlgfmjchT8CLrBLesFNwhQq3muDkP_Y&countrySet=US&api-version=1.0&query=${input}`
		)
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				console.log("res", res);
				const sA = res.results && res.results.length >= 1 ? res.results[0].address : null;

				const caResults = res.results.filter(
					(r) => r.address.countryCode == "US" && r.address.countrySubdivision == "CA"
				);
				console.log("caResults", caResults);

				if (caResults.length > 0) {
					console.log("suggested address", sA, res);
					// const sug = {...sA};
					//need to complete map to return only fields needed, street address, city and zip
					data.setSuggestedAddress({
						...data.suggestedAddress,
						address: caResults.map((r) => {
							return {
								value: `${r.address.streetNumber} ${r.address.streetName}`,
								inputValue: `${r.address.streetNumber} ${r.address.streetName}`,
								country: r.address.country,
								countryCode: r.address.countryCode,
								countrySecondarySubdivision: r.address.countrySecondarySubdivision,
								countrySubdivision: r.address.countrySubdivision,
								freeformAddress: r.address.freeformAddress,
								localName: r.address.localName,
								municipality: r.address.municipality,
								postalCode: r.address.postalCode,
								streetName: r.address.streetName,
								streetNumber: r.address.streetNumber,
							};
						}),
					});
				} else {
					console.log(res);
				}
				// updateData(data.suggestedAddress, sA);
			})
			.catch((err) => console.log("err", err));
	};

	console.log("sA", data);

	const {
		howDidYouHear,
		dueToCovid,
		whatCausedCrisis,
		crisisOther,
		race,
		firstName,
		lastName,
		address1,
		address2,
		city,
		zip,
		dob,
		email,
		phone,
		secPhone,
		whatCity,
		howManyInHousehold,
		howManyChildrenInHousehold,
		howManyChildrenInHouseholdUnder2,
		howMany18to24InHousehold,
		howMany25to54InHousehold,
		anyoneInHouseholdVeteran,
		typeOfHousing,
		employed,
		employment,
		howManySeniors,
		backRentMonths,
		backRentAmount,
		landlordPhone,
		landlordaddress1,
		landlordaddress2,
		landlordcity,
		landlordstate,
		landlordzip,
		error,
		llName,
		landlordEmail,
		preferredPronoun,
		genderIdentity,
		headOfHousehold25,
		ethnicity,
		ethnicityArray,
		bestTimeArray,
		needInterpretation,
		needInterpretationLang,
		liveInRentControlledUnit,
		livedFor5YearsMore,
		sexualOrientation,
		currentMonthlyRent,
		unpaidUtilities,
		backUtilityAmount,
		backUtilityMonths,
		appliedForRent,
		receivedAssistanceFrom,
		amountOfAssistance0,
		majorChangeHousehold,
		rentalSubsidyLowIncome,
		typeOfSubsidy,
		incurDebt,
		incurDebtType,
		incurDebtMonthsYears,
		whatMonthsCoveredByAssistance,
		bestTimeToContact,
		interestedEmployment,
		backRentOccupy,
		utilDebtNoLongerOccupy,
		deniedAssistanceCA,
		howMuchDebt,
		moveInAssistance,
		moveInAddress1,
		moveInAddress2,
		moveInCity,
		moveInState,
		moveInZip,
		housingTypeDestination,
		moveInDate,
		securityDeposit,
		firstMonthsRent,
		lastMonthsRent,
		datePaymentDue,
		moveInLandlordName,
		moveInLandlordAddress1,
		moveInLandlordAddress2,
		moveInLandlordCity,
		moveInLandlordState,
		moveInLandlordZip,
		moveInLandlordPhone,
		moveInLandlordEmail,
		Security_x0020_Deposit,
		First_x0020_Month_x0020_Rent,
		Last_x0020_Month_x0020_Rent,
		whatCausedCrisisString,
		bestTimeToContactString,
		anyonePregnant,
		assistanceTimePeriod,
		moveInAssistanceType,
		unitMovingInto,
		typeOfSubsidyMovingInto,
		namesInHousehold,
		intLangOther,
	} = data.formData;

	const { suggestedAddress, setSuggestedAddress } = data;

	const { selectedAddress, setSelectedAddress } = data;

	const updateData = async (field, value) => {
		console.log("field is ", field, validateDate(value));

		if (field !== "dob") {
			console.log("field is NOT dob");
			await data.setFormData({ ...data.formData, [field]: value });
		}

		if (field === "dob" && validateDate(value)) {
			console.log("field is DOB and validateDate", value, validateDate(value));
			await data.setFormData({ ...data.formData, [field]: value });
			await setErrorFields({ ...errorFields, ["dob"]: false });
		} else {
			if (field === "dob") {
				console.log("field is dob and error is true");
				await data.setFormData({ ...data.formData, [field]: undefined, error: true });
				await setErrorFields({ ...errorFields, ["dob"]: "01/01/1900" });
			}
		}
	};

	const updateMultiData = (nData) => {
		data.setFormData({ ...data.formData, ...nData });
	};

	const imgStyle = {
		height: 65,
		// maxWidth: "80%",
		objectFit: "cover",
		display: "block",
		margin: "0 auto",
		marginTop: 10,
		marginBottom: 10,
	};
	const cityImage = () => {
		switch (whatCity) {
			case "Emeryville":
				return Emeryville;
			case "Hayward":
				return Hayward;
			case "Oakland":
				return Oakland;
			default:
				return "";
		}
	};

	// console.log("formData", data.formData);

	const checkError = (input) => {
		console.log("input", input, input.currentTarget);
	};
	const { errorFields, setErrorFields } = data;

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
				Applicant Information
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!howDidYouHear || howDidYouHear == "")}
					>
						<InputLabel shrink htmlFor="how-did-you-hear-about-us">
							How did you hear about us?
						</InputLabel>
						<InputLabel htmlFor="how-did-you-hear-about-us-input">
							How did you hear about us?
						</InputLabel>
						<Select
							native
							value={howDidYouHear}
							onChange={(evt) => updateData("howDidYouHear", evt.target.value)}
							inputProps={{
								name: "howDidYouHear",
								id: "how-did-you-hear-about-us-input",
							}}
						>
							<option value={""}></option>
							<option value={"Advertisement on radio, billboard, or bus"}>
								Advertisement on radio, billboard, or bus
							</option>
							<option value={"Coordinated Entry Access Point"}>
								Coordinated Entry Access Point
							</option>
							<option value={"Local Government staff member or website"}>
								Local Government staff member or website
							</option>
							<option value={"Community-based organization"}>Community-based organization</option>
							<option value={"211 or 311"}>211 or 311</option>
							<option value={"Landlord"}>Landlord</option>
							<option value={"Other"}>Other</option>
						</Select>
					</FormControl>
				</Grid>
				{/* <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
            error={error && (!dueToCovid || dueToCovid == "")}
          >
            <InputLabel shrink htmlFor="dueToCovid">
              Is your housing crisis due to COVID-19?
            </InputLabel>
            <InputLabel htmlFor="dueToCovid">
              Is your housing crisis due to COVID-19?
            </InputLabel>
            <Select
              native
              value={dueToCovid}
              onChange={(evt) => {
                updateData("dueToCovid", evt.target.value);
              }}
              inputProps={{
                name: "dueToCovid",
                id: "dueToCovid",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid> */}

				{/* <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className={"MuiFormControl-marginNormal"}              
            >
              <InputLabel shrink htmlFor="whatCausedCrisis">
                {whatCausedCrisis !== undefined
                  ? `What caused your housing crisis? Mark all that apply.`
                  : ``}
              </InputLabel>
              <InputLabel htmlFor="whatCausedCrisis">
                What caused your housing crisis? Mark all that apply.
              </InputLabel>
              <Select
                native
                onChange={(evt) =>
                  updateData("whatCausedCrisis", evt.target.value)
                }
                inputProps={{
                  name: "whatCausedCrisis",
                  id: "whatCausedCrisis",
                }}
              >
                <option aria-label="None" value="" />
                <option value={`Loss of job / reduction in hours`}>
                  Loss of job / reduction in hours
                </option>
                <option value={"Landlord raised rent"}>Landlord raised rent</option>
                <option value={"COVID-19 (illness or other impact of pandemic)"}>COVID-19 (illness or other impact of pandemic)</option>
                <option value={"Other medical reasons, including increased expenses due to medical costs"}>Other medical reasons, including increased expenses due to medical costs</option>
                <option value={"Loss of childcare or increased expenditures due to childcare"}>Loss of childcare or increased expenditures due to childcare</option>
                <option value={"Debts owed"}>Debts owed</option>
                <option value={"Mental health or substance use issues"}>Mental health or substance use issues</option>
                <option value={"Divorce, separation, or household/roommate conflict"}>Divorce, separation, or household/roommate conflict</option>
                <option value={"Incarceration"}>Incarceration</option>
                <option value={"Aging out of foster care"}>Aging out of foster care</option>

                <option value={"Other"}>Other</option>

              </Select>
            </FormControl>
        </Grid> */}

				{/* <Grid item xs={12}>
          <InputLabel htmlFor="whatCausedCrisis"> What caused your housing crisis? Mark all that apply.</InputLabel>
        </Grid> */}

				{/* { Object.keys(whatCausedCrisisOptions).map(ethKey=> {        
        const item = (
        <Grid item xs={12} sm={12} key={`ethKey-${ethKey}`}>
          <FormControl  fullWidth className={"MuiFormControl-marginNormal"} error={error && (!whatCausedCrisisString || whatCausedCrisisString == "")}>
            <InputLabel shrink htmlFor="whatCausedCrisisString">
              {whatCausedCrisisOptions[ethKey].label }
            </InputLabel>
            <InputLabel htmlFor={ethKey}> { whatCausedCrisisOptions[ethKey].label }</InputLabel>
            <Select
              // native
              value={whatCausedCrisisOptions[ethKey].selected}
              MenuProps={MenuProps}
              input={<Input />}
              name={ethKey}
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={async (e) => {
                  const nArr = e.target.value; 
                  whatCausedCrisisOptions[ethKey].selected = nArr;
                  let allEth = [];
                  Object.keys(whatCausedCrisisOptions).forEach(eO=>allEth = allEth.concat(whatCausedCrisisOptions[eO].selected));
                  
                  updateMultiData({ whatCausedCrisisString: allEth.join(';')});
                }
              }
              inputProps={{
                name: "crisisArray",
                id: ethKey,
              }}
            >
              {whatCausedCrisisOptions[ethKey].values.map((name) => {
                return (                
                <MenuItem key={name} value={name}>
                  <Checkbox className={'bacsCheckbox'} checked={whatCausedCrisisOptions[ethKey].selected.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>

              )})}
            </Select>
          </FormControl>
        </Grid>
        )
        console.log('item', item);
        return item
        })
      }


        {whatCausedCrisis && whatCausedCrisis == "Other" ? (
          <Grid item xs={12} sm={12}>          
            <TextField
              required
              id="crisisOther"
              name="crisisOther"
              label="Please describe what caused your crisis in a few words"
              fullWidth
              defaultValue={crisisOther}
              onChange={(e) => updateData(e.target.name, e.target.value)}
            />
          </Grid>
        ) : null} */}

				<Grid item xs={12}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!appliedForRent || appliedForRent == "")}
					>
						<InputLabel htmlFor="appliedForRent" style={{ marginTop: -40 }}>
							Have you applied for and/or received rent or financial assistance since March 2020
							from any other program or resources?* This may include previous assistance from the
							San Francisco Emergency Rental Assistance Program, Season of Sharing, or CA COVID-19
							Rent Relief Program. This will NOT disqualify you for additional financial help, but
							receiving help from two or more programs for the same months of rent will not be
							permitted.
						</InputLabel>
						<Select
							native
							value={appliedForRent}
							onChange={(evt) => {
								updateData("appliedForRent", evt.target.value);
							}}
							inputProps={{
								name: "appliedForRent",
								id: "appliedForRent",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{appliedForRent && appliedForRent == "Yes" && (
					<Grid item xs={12} sm={6}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							required
							error={error && (!receivedAssistanceFrom || receivedAssistanceFrom == "")}
						>
							<InputLabel shrink htmlFor="receivedAssistanceFrom">
								If yes, from where did you receive assistance?
							</InputLabel>
							<TextField
								required
								error={error && (!receivedAssistanceFrom || receivedAssistanceFrom == "")}
								id="receivedAssistanceFrom"
								name="receivedAssistanceFrom"
								label="If yes, from where did you receive assistance?"
								fullWidth
								defaultValue={receivedAssistanceFrom}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				)}

				{/* {appliedForRent && appliedForRent == "Yes" && (
					<Grid item xs={12} sm={6}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							required
							error={error && (!amountOfAssistance0 || amountOfAssistance0 == "")}
						>
							<InputLabel shrink htmlFor="amountOfAssistance0">
								What was the amount of assistance you received?
							</InputLabel>
							<TextField
								required
								error={error && (!receivedAssistanceFrom || receivedAssistanceFrom == "")}
								id="amountOfAssistance0"
								name="amountOfAssistance0"
								label="What was the amount of assistance you received?"
								fullWidth
								defaultValue={amountOfAssistance0}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				)} */}

				{appliedForRent && appliedForRent == "Yes" && (
					<Grid item xs={12} sm={6} className={"longText"}>
						<FormControl required fullWidth className={"MuiFormControl-marginNormal"}>
							<TextField
								required
								error={error && (!amountOfAssistance0 || amountOfAssistance0 == "")}
								id="amountOfAssistance0"
								name="amountOfAssistance0"
								label={<span>What was the amount of assistance you received?</span>}
								type={"number"}
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								defaultValue={amountOfAssistance0}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				)}

				{appliedForRent && appliedForRent == "Yes" && (
					<Grid item xs={12} sm={6}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							required
							error={
								error && (!whatMonthsCoveredByAssistance || whatMonthsCoveredByAssistance == "")
							}
						>
							<InputLabel shrink htmlFor="whatMonthsCoveredByAssistance">
								What months were covered by the assistance you received?
							</InputLabel>
							<TextField
								required
								error={
									error && (!whatMonthsCoveredByAssistance || whatMonthsCoveredByAssistance == "")
								}
								id="whatMonthsCoveredByAssistance"
								name="whatMonthsCoveredByAssistance"
								label="What months were covered by the assistance you received?"
								fullWidth
								defaultValue={whatMonthsCoveredByAssistance}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				)}

				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!firstName || firstName == "" || firstName.replace(/ /g, "") == "")}
					>
						<InputLabel shrink htmlFor="firstName">
							First Name
						</InputLabel>
						<TextField
							error={error && (!firstName || firstName == "" || firstName.replace(/ /g, "") == "")}
							required
							// error={error && (!firstName || firstName == "")}
							id="firstName"
							name="firstName"
							label="First Name"
							fullWidth
							autoComplete="fname"
							defaultValue={firstName}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!lastName || lastName == "" || (lastName && lastName.trim() == ""))}
					>
						<InputLabel shrink htmlFor="lastName">
							Last Name
						</InputLabel>
						<TextField
							required
							error={error && (!lastName || lastName == "" || (lastName && lastName.trim() == ""))}
							id="lastName"
							name="lastName"
							label="Last Name"
							fullWidth
							autoComplete="lname"
							defaultValue={lastName}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="dob"
							label="Date of Birth (MM/DD/YYYY)"
							minDate={new Date("1900-01-01")}
							// onInvalid={(a) => {
							// 	console.log("on invalid date", a);
							// 	setErrorFields({ ...errorFields, ["dob"]: true });
							// }}
							invalidDateMessage={
								<span style={{ color: "red" }}>Invalid Date - Please enter as MM/DD/YYYY</span>
							}
							fullWidth
							name={"dob"}
							value={dob}
							required
							error={error && (!dob || !validateDate(dob) || dob.indexOf("_") >= 0 || dob == "")}
							// onChange={handleDateChange}
							// defaultValue={dob}
							onChange={async (date, value) => {
								console.log("value", value);
								console.log("validDate check", validateDate(value));

								await updateData("dob", value);

								// if (!validateDate(value)) {
								// 	await setErrorFields({ ...errorFields, ["dob"]: true });
								// } else {
								// 	if (errorFields["dob"]) await setErrorFields({ ...errorFields, ["dob"]: false });
								// }
							}}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
						/>
					</MuiPickersUtilsProvider>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal"}
						error={error && (!sexualOrientation || sexualOrientation == "")}
					>
						<InputLabel shrink htmlFor="sexualOrientation">
							Sexual Orientation
						</InputLabel>
						<InputLabel htmlFor="sexualOrientation">Sexual Orientation</InputLabel>
						<Select
							native
							value={sexualOrientation}
							// onChange={handleChange}
							name={"sexualOrientation"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "sexualOrientation",
								id: "sexualOrientation",
							}}
						>
							<option aria-label="None" value="" />
							{sexualOrientationChoices.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal"}
						error={error && (!genderIdentity || genderIdentity == "")}
					>
						<InputLabel shrink htmlFor="genderIdentity">
							Gender Identity
						</InputLabel>
						<InputLabel htmlFor="genderIdentity">Gender Identity</InputLabel>
						<Select
							native
							value={genderIdentity}
							// onChange={handleChange}
							name={"genderIdentity"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "genderIdentity",
								id: "genderIdentity",
							}}
						>
							<option aria-label="None" value="" />
							{genderIdentityChoices.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal"}
						error={error && (!preferredPronoun || preferredPronoun == "")}
					>
						<InputLabel shrink htmlFor="preferredPronoun">
							Preferred Pronouns
						</InputLabel>
						<InputLabel htmlFor="preferredPronoun">Preferred Pronouns</InputLabel>
						<Select
							native
							value={preferredPronoun}
							// onChange={handleChange}
							name={"preferredPronoun"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "preferredPronoun",
								id: "preferredPronoun",
							}}
						>
							<option aria-label="None" value="" />
							{preferredPronounChoices.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth
						required
						error={error && (!phone || phone == "" || (phone && phone.trim() == ""))}
					>
						<InputLabel shrink htmlFor="phone">
							Primary Phone #
						</InputLabel>
						{/* <InputLabel htmlFor="phone">Primary Phone #</InputLabel> */}
						<TextField
							id="phone"
							aria-describedby="phone-helper-text"
							name={"phone"}
							value={phone ? phone : ""}
							label={"Primary Phone #"}
							error={error && (!phone || phone == "" || (phone && phone.trim() == ""))}
							onChange={(e) =>
								updateData(
									e.target.name,
									formatUSNumber(e.target.value ? e.target.value.toString() : e.target.value)
								)
							}
						/>
						<FormHelperText id="phone-helper-text">{/* Your email address. */}</FormHelperText>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl fullWidth>
						<InputLabel shrink htmlFor="secPhone">
							Secondary Phone #
						</InputLabel>
						{/* <InputLabel htmlFor="phone">Primary Phone #</InputLabel> */}
						<TextField
							id="secPhone"
							aria-describedby="secPhone-helper-text"
							name={"secPhone"}
							value={secPhone ? secPhone : ""}
							label={"Secondary Phone #"}
							// error={error && (!secPhone || secPhone == "")}
							onChange={(e) =>
								updateData(
									e.target.name,
									formatUSNumber(e.target.value ? e.target.value.toString() : e.target.value)
								)
							}
						/>
						<FormHelperText id="secPhone-helper-text">{/* Your email address. */}</FormHelperText>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<TextField
						required
						error={(error && (!email || email == "")) || errorFields.email}
						id="email"
						name="email"
						label="E-mail Address"
						fullWidth
						autoComplete="email"
						defaultValue={email}
						onChange={(e) => {
							var isValidEmail =
								/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
							console.log("is valid email", isValidEmail.test(e.target.value));
							updateData(e.target.name, e.target.value);
						}}
						onBlur={(e) => {
							var isValidEmail =
								/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
							if (!isValidEmail.test(e.target.value)) {
								setErrorFields({ ...errorFields, [e.target.name]: true });
							} else {
								setErrorFields({ ...errorFields, [e.target.name]: false });
							}
							console.log("errorFields", errorFields);
						}}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!needInterpretation || needInterpretation == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="needInterpretation">
							Do you need interpretation services?
						</InputLabel>
						<InputLabel htmlFor="needInterpretation">
							Do you need interpretation services?
						</InputLabel>
						<Select
							native
							value={needInterpretation}
							onChange={(evt) => updateData("needInterpretation", evt.target.value)}
							inputProps={{
								name: "needInterpretation",
								id: "needInterpretation",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						disabled={!needInterpretation || needInterpretation != "yes"}
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required={needInterpretation && needInterpretation == "yes" ? true : false}
						error={
							error &&
							needInterpretation &&
							needInterpretation == "yes" &&
							(!needInterpretationLang || needInterpretationLang == "")
						}
					>
						<InputLabel shrink htmlFor="needInterpretationLang">
							If Yes, what language?
						</InputLabel>
						<InputLabel htmlFor="needInterpretationLang">If Yes, what language?</InputLabel>
						<Select
							native
							value={needInterpretationLang}
							// onChange={handleChange}
							name={"needInterpretationLang"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "needInterpretationLang",
								id: "needInterpretationLang",
							}}
						>
							<option aria-label="None" value="" />
							{Languages.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				{/* {needInterpretation && needInterpretationLang && needInterpretationLang == "Other" && ( */}
				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						disabled={!needInterpretation || needInterpretationLang !== "Other"}
						required={needInterpretation && needInterpretationLang == "Other"}
						error={
							error &&
							needInterpretationLang == "Other" &&
							(!intLangOther || intLangOther == "" || (intLangOther && intLangOther.trim() == ""))
						}
					>
						<InputLabel shrink htmlFor="intLangOther">
							Other language for interpretation services
						</InputLabel>
						<TextField
							error={
								error &&
								needInterpretation &&
								needInterpretationLang == "Other" &&
								(!intLangOther || intLangOther == "" || (intLangOther && intLangOther.trim() == ""))
							}
							id="intLangOther"
							required={needInterpretation && needInterpretationLang == "Other"}
							name="intLangOther"
							label="Other language for interpretation services"
							disabled={!needInterpretation || needInterpretationLang !== "Other"}
							fullWidth
							defaultValue={intLangOther}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>
				{/* )} */}

				{/* <Grid item xs={12} sm={12}>
          <FormControl required fullWidth className={"MuiFormControl-marginNormal"} required error={error && (!sexualOrientation || sexualOrientation == "")}>
            <InputLabel shrink htmlFor="bestTimeToContact">
              When is the best time to reach you during the week (Monday - Friday)?  Choose all that apply
            </InputLabel>
            <InputLabel htmlFor="bestTimeToContact">When is the best time to reach you during the week (Monday - Friday)?  Choose all that apply</InputLabel>
            <Select
              native
              value={bestTimeToContact}
              // onChange={handleChange}
              error={(error && (!bestTimeToContact || email == "")) || errorFields.email}
              name={"bestTimeToContact"}
              onChange={(e) => updateData(e.target.name, e.target.value)}
              inputProps={{
                name: "bestTimeToContact",
                id: "bestTimeToContact",
              }}
            >
              <option aria-label="None" value="" />
              {bestTimeToContactOptions.map((r) => (
                <option value={r} key={`control-${r}`}>
                  {r}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

				{/* <Grid item xs={12}>
          <InputLabel htmlFor="bestTimeToContact"> When is the best time to reach you during the week (Monday - Friday)?  Choose all that apply</InputLabel>
        </Grid> */}

				{Object.keys(bestTimeToContactOptions).map((ethKey) => {
					const item = (
						<Grid item xs={12} sm={12} key={`ethKey-${ethKey}`}>
							<FormControl
								fullWidth
								className={"MuiFormControl-marginNormal"}
								required
								error={
									error &&
									(!bestTimeToContactString ||
										bestTimeToContactString == "" ||
										(bestTimeToContactString &&
											bestTimeToContactString.length > 0 &&
											bestTimeToContactString.trim() == ""))
								}
							>
								<InputLabel shrink htmlFor="bestTimeToContactString">
									{bestTimeToContactOptions[ethKey].label}
								</InputLabel>
								<InputLabel htmlFor={ethKey}> {bestTimeToContactOptions[ethKey].label}</InputLabel>
								<Select
									// native
									value={bestTimeToContactOptions[ethKey].selected}
									MenuProps={MenuProps}
									input={<Input />}
									name={ethKey}
									multiple
									renderValue={(selected) => selected.join(", ")}
									onChange={async (e) => {
										const nArr = e.target.value;
										bestTimeToContactOptions[ethKey].selected = nArr;
										let allEth = [];
										Object.keys(bestTimeToContactOptions).forEach(
											(eO) => (allEth = allEth.concat(bestTimeToContactOptions[eO].selected))
										);

										updateMultiData({ bestTimeToContactString: allEth.join(";") });
									}}
									inputProps={{
										name: "bestTimeArray",
										id: ethKey,
									}}
								>
									{bestTimeToContactOptions[ethKey].values.map((name) => {
										return (
											<MenuItem key={name} value={name}>
												<Checkbox
													className={"bacsCheckbox"}
													checked={bestTimeToContactOptions[ethKey].selected.indexOf(name) > -1}
												/>
												<ListItemText primary={name} />
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					);
					console.log("item", item);
					return item;
				})}

				<Grid item xs={12}>
					<InputLabel htmlFor="ethnicity" style={{ fontWeight: 600 }}>
						{" "}
						Which best describes your race/ethnicity? (Mark ALL check boxes that apply)
					</InputLabel>
					<Typography
						variant="h6"
						gutterBottom
						style={{ color: "#666", fontSize: "1rem", paddingTop: 5 }}
					>
						Among the categories listed below, select one or more options for the race/ethnicity you
						most closely identify. View more detailed options by clicking on each of the categories.
					</Typography>
				</Grid>

				{Object.keys(ethnicityObject).map((ethKey) => {
					const item = (
						<Grid item xs={12} sm={12} m={6} key={`ethKey-${ethKey}`}>
							<FormControl
								fullWidth
								required={
									!ethnicity || ethnicity == "" || (ethnicity && ethnicity.trim() == "")
										? true
										: false
								}
								className={"MuiFormControl-marginNormal"}
								error={
									error && (!ethnicity || ethnicity == "" || (ethnicity && ethnicity.trim() == ""))
								}
							>
								<InputLabel shrink htmlFor="ethnicity">
									{ethnicityObject[ethKey].label}
								</InputLabel>
								<InputLabel htmlFor={ethKey}> {ethnicityObject[ethKey].label}</InputLabel>
								<Select
									// native
									value={ethnicityObject[ethKey].selected}
									className={"multiSelect"}
									MenuProps={MenuProps}
									input={<Input />}
									name={ethKey}
									multiple
									renderValue={(selected) => selected.join(", ")}
									onChange={async (e) => {
										const nArr = e.target.value;
										ethnicityObject[ethKey].selected = nArr;
										let allEth = [];
										Object.keys(ethnicityObject).forEach(
											(eO) => (allEth = allEth.concat(ethnicityObject[eO].selected))
										);

										updateMultiData({ ethnicity: allEth.join(";") });
									}}
									inputProps={{
										name: "ethnicityArray",
										id: ethKey,
									}}
								>
									{ethnicityObject[ethKey].values.map((name) => {
										return (
											<MenuItem key={name} value={name}>
												<Checkbox
													className={"bacsCheckbox"}
													checked={ethnicityObject[ethKey].selected.indexOf(name) > -1}
												/>
												<ListItemText primary={name} />
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					);
					console.log("item", item);
					return item;
				})}

				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Current Address
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Autocomplete
						onError={(e) => {
							console.log("autocomplete did error", e);
						}}
						options={suggestedAddress.address}
						getOptionLabel={(option) => {
							console.log("option", option);
							return option && option.value && option.inputValue
								? `${option.freeformAddress}`
								: `${option.toString()}`;
						}}
						value={address1 ? address1 : ""}
						onInputChange={(e, newValue) => {
							console.log("autocomplete on change", newValue, e, e ? e.target : "no target");
							setSelectedAddress(newValue);
							if (
								e &&
								e.target &&
								e.target.value &&
								e.target.value.length > 4 &&
								e.target.value.indexOf(" ") >= 0
							) {
								findAddress(e.target.value);
							} else {
								setSuggestedAddress({ address: [] });
							}
							if (e && e.target) {
								updateData(e.target.name, e.target.value);
							}
						}}
						clearOnBlur={false}
						onChange={(event, option) => {
							console.log("on auto did change", option);
							if (option && option.streetNumber)
								data.setFormData({
									...data.formData,
									address1: `${option.streetNumber} ${option.streetName}`,
									city: option.localName,
									zip: option.postalCode,
								});
						}}
						renderOption={(option) => {
							console.log("render option", option);
							if (address1 !== `${option.streetNumber} ${option.streeName}`) {
							}
							return <div>{`${option.freeformAddress}`}</div>;
						}}
						renderInput={(params) => {
							console.log("input params", params, suggestedAddress, selectedAddress);
							return (
								<TextField
									{...params}
									id="address1"
									name="address1"
									label="Address line 1"
									fullWidth
									required
									error={
										error && (!address1 || address1 == "" || (address1 && address1.trim() == ""))
									}
									value={address1}
								/>
							);
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="address2"
						name="address2"
						label="Unit or Apartment #"
						fullWidth
						autoComplete="address-line2"
						defaultValue={address2}
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						error={error && (!city || city == "" || (city && city.trim() == ""))}
						id="city"
						name="city"
						label="City"
						fullWidth
						autoComplete="address-level2"
						value={city ? city : ""}
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						error={error && (!zip || zip == "" || (zip && zip.length < 5))}
						id="zip"
						name="zip"
						label="Zip / Postal code"
						fullWidth
						autoComplete="billing postal-code"
						type={"number"}
						onBlur={() => {
							if (zip && zip != "" && zip.length < 5) {
								console.log("setting error fields");
								setErrorFields({ ...errorFields, zip: true });
							}
						}}
						// defaultValue={zip}
						value={zip ? zip : ""}
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Divider flexItem />
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Household Information
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography gutterBottom style={{ color: "#666" }}>
						Please note: ‘Household’ refers to a single person or group of people living together
						who depend on the same income or share income (regardless of marital status, age, sexual
						orientation, or gender identity). Others living in the same home who you do not share
						income with (such as roommates), but who need rental assistance, should apply as
						separate households. Note: ‘Household’ includes children who may be temporarily away
						from the home because of placement in foster care.
					</Typography>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!typeOfHousing || typeOfHousing == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="type-of-housing">
							Where are you currently living?
						</InputLabel>
						<InputLabel htmlFor="typeOfHousing">Where are you currently living?</InputLabel>
						<Select
							native
							value={typeOfHousing}
							onChange={(evt) => updateData("typeOfHousing", evt.target.value)}
							inputProps={{
								name: "typeOfHousing",
								id: "typeOfHousing",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Rental unit – Apartment, room/shared housing, or house"}>
								Rental unit – Apartment, room/shared housing, or house
							</option>
							<option value={"Rental unit – Single Room Occupancy (SRO)"}>
								Rental unit – Single Room Occupancy (SRO)
							</option>
							<option value={"Unhoused – Emergency shelter"}>Unhoused – Emergency shelter</option>
							<option
								value={
									"Unhoused – Staying in your car, on the street, or other place not meant as housing"
								}
							>
								Unhoused – Staying in your car, on the street, or other place not meant as housing
							</option>
							<option
								value={"Don’t Know/Other (jail/prison, hospital, treatment program, hotel, etc.)"}
							>
								Don’t Know/Other (jail/prison, hospital, treatment program, hotel, etc.)
							</option>
						</Select>
					</FormControl>
				</Grid>

				{typeOfHousing && typeOfHousing.indexOf("Rental") >= 0 && (
					<Grid item xs={12}>
						<Grid item xs={12}>
							<InputLabel htmlFor="ethnicity">
								{" "}
								Do any of the following apply to your CURRENT housing situation?*
							</InputLabel>
							<Typography
								variant="h6"
								gutterBottom
								style={{ color: "#666", fontSize: "1rem", paddingTop: 5 }}
							>
								<List style={{ paddingLeft: 50 }}>
									<ListItem style={{ display: "inline" }}>
										• A program or agency pays all or part of my rent
									</ListItem>
									<br />
									<ListItem style={{ display: "inline" }}>
										• I have a housing voucher/Section 8
									</ListItem>
									<br />
									<ListItem style={{ display: "inline" }}>
										• I live in an affordable housing unit and/or got my housing through a lottery
										(DAHLIA)
									</ListItem>
									<br />
									<ListItem></ListItem>
								</List>
							</Typography>
						</Grid>

						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							required
							error={error && (!rentalSubsidyLowIncome || rentalSubsidyLowIncome == "")}
						>
							{/* <InputLabel shrink htmlFor="rentalSubsidyLowIncome">
								Do any of the following apply to your CURRENT housing situation?*
							</InputLabel> */}
							<InputLabel htmlFor="rentalSubsidyLowIncome">
								Do any of the above apply to your CURRENT housing situation?*
							</InputLabel>
							<Select
								native
								error={error && (!rentalSubsidyLowIncome || rentalSubsidyLowIncome == "")}
								value={rentalSubsidyLowIncome}
								onChange={(evt) => {
									updateData("rentalSubsidyLowIncome", evt.target.value);
								}}
								inputProps={{
									name: "rentalSubsidyLowIncome",
									id: "rentalSubsidyLowIncome",
								}}
							>
								<option aria-label="None" value="" />
								<option value={"yes"}>Yes</option>
								<option value={"no"}>No</option>
								<option value={"not sure"}>Not sure</option>
							</Select>
						</FormControl>
					</Grid>
				)}

				{rentalSubsidyLowIncome &&
					typeOfHousing &&
					typeOfHousing.indexOf("Rental") >= 0 &&
					(rentalSubsidyLowIncome == "yes" || rentalSubsidyLowIncome == "not sure") && (
						<Grid item xs={12} sm={6}>
							<FormControl
								fullWidth
								className={"MuiFormControl-marginNormal"}
								required
								error={
									error &&
									((rentalSubsidyLowIncome && rentalSubsidyLowIncome == "yes" && !typeOfSubsidy) ||
										typeOfSubsidy == "")
								}
							>
								<InputLabel shrink htmlFor="typeOfSubsidy">
									Please specify the type of housing program you are in.
								</InputLabel>
								<InputLabel htmlFor="typeOfSubsidy">
									Please specify the type of housing program you are in.
								</InputLabel>
								<Select
									native
									value={typeOfSubsidy}
									onChange={(evt) => updateData("typeOfSubsidy", evt.target.value)}
									inputProps={{
										name: "typeOfSubsidy",
										id: "typeOfSubsidy",
									}}
								>
									<option aria-label="None" value="" />
									<option
										value={"Below Market Rate Unit (Inclusionary) via DAHLIA housing lottery"}
									>
										Below Market Rate Unit (Inclusionary) via DAHLIA housing lottery
									</option>
									<option value={"100% Affordable Unit via DAHLIA housing lottery"}>
										100% Affordable Unit via DAHLIA housing lottery
									</option>
									<option
										value={
											"Permanent Supportive Housing (housing for people who used to be homeless)"
										}
									>
										Permanent Supportive Housing (housing for people who used to be homeless)
									</option>
									<option value={"Small/Big Sites Program Unit"}>
										Small/Big Sites Program Unit
									</option>
									<option value={"Housing Choice Voucher (Section 8)"}>
										Housing Choice Voucher (Section 8)
									</option>
									<option value={"Rapid Rehousing (monthly rental assistance program)"}>
										Rapid Rehousing (monthly rental assistance program)
									</option>
									<option value={"Public Housing (San Francisco Housing Authority)"}>
										Public Housing (San Francisco Housing Authority)
									</option>
									<option value={"HOPE SF"}>HOPE SF</option>
									<option value={"RAD"}>RAD</option>
									<option value={"Veteran's Housing"}>Veteran's Housing</option>
									<option value={"Co-op"}>Co-op</option>
									<option value={"Other Tenant-Based Subsidy/Voucher"}>
										Other Tenant-Based Subsidy/Voucher
									</option>
									<option value={"I don't know"}>I don't know</option>
								</Select>
							</FormControl>
						</Grid>
					)}

				{typeOfHousing &&
					typeOfHousing == "Rental unit – Apartment, room/shared housing, or house" && (
						<Grid item xs={12} sm={6}>
							<FormControl
								fullWidth
								className={"MuiFormControl-marginNormal"}
								required
								error={error && (!liveInRentControlledUnit || liveInRentControlledUnit == "")}
							>
								<InputLabel shrink htmlFor="liveInRentControlledUnit">
									Do you live in a{" "}
									<a
										target="_blank"
										href="https://sf.gov/information/find-out-if-san-franciscos-rental-laws-apply-you"
									>
										rent-controlled unit
									</a>
									?
								</InputLabel>
								<InputLabel htmlFor="liveInRentControlledUnit">
									Do you live in a rent-controlled unit?
								</InputLabel>
								<Select
									native
									error={error && (!liveInRentControlledUnit || liveInRentControlledUnit == "")}
									value={liveInRentControlledUnit}
									onChange={(evt) => updateData("liveInRentControlledUnit", evt.target.value)}
									inputProps={{
										name: "liveInRentControlledUnit",
										id: "liveInRentControlledUnit",
									}}
								>
									<option aria-label="None" value="" />
									<option value={"yes"}>Yes</option>
									<option value={"no"}>No</option>
								</Select>
							</FormControl>
						</Grid>
					)}

				{liveInRentControlledUnit && liveInRentControlledUnit == "yes" && (
					<Grid item xs={12} sm={6}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							required
							error={error && (!livedFor5YearsMore || livedFor5YearsMore == "")}
						>
							<InputLabel shrink htmlFor="livedFor5YearsMore">
								Have you lived there for 5 years or more?
							</InputLabel>
							<InputLabel htmlFor="livedFor5YearsMore">
								Have you lived there for 5 years or more?
							</InputLabel>
							<Select
								native
								value={livedFor5YearsMore}
								onChange={(evt) => updateData("livedFor5YearsMore", evt.target.value)}
								inputProps={{
									name: "livedFor5YearsMore",
									id: "livedFor5YearsMore",
								}}
							>
								<option aria-label="None" value="" />
								<option value={"yes"}>Yes</option>
								<option value={"no"}>No</option>
							</Select>
						</FormControl>
					</Grid>
				)}

				<Grid item xs={12}>
					<FormControl
						required
						error={
							error &&
							(!namesInHousehold ||
								namesInHousehold == "" ||
								(namesInHousehold && namesInHousehold.trim() == ""))
						}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-in-household">
							Please provide the first and last names of all members of your household age 18 or
							older.
						</InputLabel>
						{/* <InputLabel htmlFor="namesInHousehold">
							Please provide the first and last names of all members of your household age 18 or
							older.
						</InputLabel> */}

						<TextField
							// disabled={!toaBackRent}
							id="namesInHousehold"
							name="namesInHousehold"
							label={
								"Please provide the first and last names of all members of your household age 18 or older."
							}
							fullWidth
							required
							value={namesInHousehold ? namesInHousehold : ""}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
						{/* <Select
							native
							value={howManyInHousehold}
							onChange={(evt) => updateData("howManyInHousehold", evt.target.value)}
							inputProps={{
								name: "howManyInHousehold",
								id: "how-many-in-household",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5"}>5</option>
							<option value={"6"}>6</option>
							<option value={"7"}>7</option>
							<option value={"8"}>8</option>
							<option value={"9+"}>9+</option>
						</Select> */}
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl
						required
						error={error && (!majorChangeHousehold || majorChangeHousehold == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						{/* <InputLabel shrink htmlFor="majorChangeHousehold">
              Have you had a major change of who's a part of your household (e.g., divorce, death ) in past 12 mos?
            </InputLabel> */}
						<InputLabel htmlFor="majorChangeHousehold">
							Have you had a major change of who's a part of your household (e.g., birth, divorce,
							death ) in past 12 months?
						</InputLabel>
						<Select
							native
							value={majorChangeHousehold}
							onChange={(evt) => updateData("majorChangeHousehold", evt.target.value)}
							inputProps={{
								name: "majorChangeHousehold",
								id: "majorChangeHousehold",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #4 */}
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						error={error && (!anyonePregnant || anyonePregnant == "")}
					>
						<InputLabel shrink htmlFor="anyonePregnant">
							Is anyone in your household currently pregnant?
						</InputLabel>
						<InputLabel htmlFor="anyonePregnant">
							Is anyone in your household currently pregnant?
						</InputLabel>
						<Select
							native
							required
							value={anyonePregnant}
							onChange={(evt) => updateData("anyonePregnant", evt.target.value)}
							inputProps={{
								name: "anyonePregnant",
								id: "anyonePregnant",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={
							error && (!howManyChildrenInHouseholdUnder2 || howManyChildrenInHouseholdUnder2 == "")
						}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-children-household">
							How many children in the household are 0-2 years old?
						</InputLabel>
						<InputLabel htmlFor="howManyChildrenInHousehold">
							How many children in the household are 0-2 years old?
						</InputLabel>
						<Select
							native
							value={howManyChildrenInHouseholdUnder2}
							onChange={(evt) => updateData("howManyChildrenInHouseholdUnder2", evt.target.value)}
							inputProps={{
								name: "howManyChildrenInHouseholdUnder2",
								id: "how-many-children-household2",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!howManyChildrenInHousehold || howManyChildrenInHousehold == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-children-household">
							How many children in the household between the ages 3-17 years old?
						</InputLabel>
						<InputLabel htmlFor="howManyChildrenInHousehold">
							How many children in the household between the ages 3-17 years old?
						</InputLabel>
						<Select
							native
							value={howManyChildrenInHousehold}
							onChange={(evt) => updateData("howManyChildrenInHousehold", evt.target.value)}
							inputProps={{
								name: "howManyChildrenInHousehold",
								id: "how-many-children-household",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!howMany18to24InHousehold || howMany18to24InHousehold == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-18to24-household">
							How many people are between the ages of 18-24 years?
						</InputLabel>
						<InputLabel htmlFor="howMany18to24InHousehold">
							How many people are between the ages of 18-24 years?
						</InputLabel>
						<Select
							native
							value={howMany18to24InHousehold}
							onChange={(evt) => updateData("howMany18to24InHousehold", evt.target.value)}
							inputProps={{
								name: "howMany18to24InHousehold",
								id: "how-many-18to24-household",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!howMany25to54InHousehold || howMany25to54InHousehold == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-25to54-household">
							How many people are between the ages of 25-61 years?
						</InputLabel>
						<InputLabel htmlFor="howMany25to54InHousehold">
							How many people are between the ages of 25-61 years?
						</InputLabel>
						<Select
							native
							value={howMany25to54InHousehold}
							onChange={(evt) => updateData("howMany25to54InHousehold", evt.target.value)}
							inputProps={{
								name: "howMany25to54InHousehold",
								id: "how-many-25to54-household",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!howManySeniors || howManySeniors == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-seniors">
							How many people are 62 years or older?
						</InputLabel>
						<InputLabel htmlFor="howManySeniors">How many people are 62 years or older?</InputLabel>
						<Select
							native
							value={howManySeniors}
							onChange={(evt) => updateData("howManySeniors", evt.target.value)}
							inputProps={{
								name: "howManySeniors",
								id: "how-many-seniors",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!headOfHousehold25 || headOfHousehold25 == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="headOfHousehold25">
							Are you (the head of household) under the age of 25?
						</InputLabel>
						<InputLabel htmlFor="headOfHousehold25">
							Are you (the head of household) under the age of 25?
						</InputLabel>
						<Select
							native
							value={headOfHousehold25}
							onChange={(evt) => updateData("headOfHousehold25", evt.target.value)}
							inputProps={{
								name: "headOfHousehold25",
								id: "headOfHousehold25",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!anyoneInHouseholdVeteran || anyoneInHouseholdVeteran == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="household-veteran">
							Are you or any other adult in the household a veteran?
						</InputLabel>
						<InputLabel htmlFor="anyoneInHouseholdVeteran">
							Are you or any other adult in the household a veteran?
						</InputLabel>
						<Select
							native
							value={anyoneInHouseholdVeteran}
							onChange={(evt) => updateData("anyoneInHouseholdVeteran", evt.target.value)}
							inputProps={{
								name: "anyoneInHouseholdVeteran",
								id: "household-veteran",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* <Grid item xs={12} sm={6}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
            error={error && (!householdPregnant || householdPregnant == "")}
          >
            <InputLabel shrink htmlFor="householdPregnant">
              Is anyone in your household currently pregnant?
            </InputLabel>
            <InputLabel htmlFor="householdPregnant">
              Is anyone in your household currently pregnant?
            </InputLabel>
            <Select
              native
              required
              value={householdPregnant}
              onChange={(evt) =>
                updateData("householdPregnant", evt.target.value)
              }
              inputProps={{
                name: "householdPregnant",
                id: "householdPregnant",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid> */}

				{/* <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="section8Voucher">
              Do you currently have a Section 8 Voucher?
            </InputLabel>
            <InputLabel htmlFor="section8Voucher">
              Do you currently have a Section 8 Voucher?
            </InputLabel>
            <Select
              native
              value={section8Voucher}
              onChange={(evt) =>
                updateData("section8Voucher", evt.target.value)
              }
              inputProps={{
                name: "section8Voucher",
                id: "section8Voucher",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"yes"}>Yes</option>
              <option value={"no"}>No</option>
            </Select>
          </FormControl>
        </Grid> */}
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Employment Status
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						<InputLabel shrink htmlFor="employed">
							Are you currently employed?
						</InputLabel>
						<InputLabel htmlFor="employed">Are you currently employed?</InputLabel>
						<Select
							native
							value={employed}
							onChange={(evt) => updateData("employed", evt.target.value)}
							inputProps={{
								name: "employed",
								id: "employed",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						<InputLabel shrink htmlFor="employment">
							If employed, what is your employment type?
						</InputLabel>
						<InputLabel htmlFor="employment">If employed, what is your employment type?</InputLabel>
						<Select
							native
							value={employment}
							onChange={(evt) => updateData("employment", evt.target.value)}
							inputProps={{
								name: "employment",
								id: "employment",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Full-time"}>Full-time</option>
							<option value={"Part-time"}>Part-time</option>
							<option value={"Hourly"}>Hourly</option>
							<option value={"Seasonal"}>Seasonal</option>
							<option value={"Informal"}>Informal</option>
							<option value={"Gig worker"}>Gig worker</option>
							<option value={"Other"}>Other</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						<InputLabel shrink htmlFor="interestedEmployment">
							Are you interested in increasing your income through employment or education?
						</InputLabel>
						<InputLabel htmlFor="interestedEmployment">
							Are you interested in increasing your income through employment or education?
						</InputLabel>
						<Select
							native
							value={interestedEmployment}
							onChange={(evt) => updateData("interestedEmployment", evt.target.value)}
							inputProps={{
								name: "interestedEmployment",
								id: "interestedEmployment",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Back Rent
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						style={{ marginTop: 0 }}
						required
						error={error && (!backRentOccupy || backRentOccupy == "")}
					>
						<InputLabel shrink htmlFor="backRentOccupy">
							Are you applying for back rent on a unit you currently occupy?
						</InputLabel>
						<InputLabel htmlFor="backRentOccupy">
							Are you applying for back rent on a unit you currently occupy?
						</InputLabel>
						<Select
							native
							error={error && (!backRentOccupy || backRentOccupy == "")}
							value={backRentOccupy}
							onChange={(evt) => updateData("backRentOccupy", evt.target.value)}
							inputProps={{
								name: "backRentOccupy",
								id: "backRentOccupy",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						style={{ marginTop: 0 }}
						required
						error={error && (!utilDebtNoLongerOccupy || utilDebtNoLongerOccupy == "")}
					>
						<InputLabel shrink htmlFor="utilDebtNoLongerOccupy">
							Do you have rental debt from a unit you no longer occupy?
						</InputLabel>
						<InputLabel htmlFor="utilDebtNoLongerOccupy">
							Do you have rental debt from a unit you no longer occupy?
						</InputLabel>
						<Select
							native
							error={error && (!utilDebtNoLongerOccupy || utilDebtNoLongerOccupy == "")}
							value={utilDebtNoLongerOccupy}
							onChange={(evt) => updateData("utilDebtNoLongerOccupy", evt.target.value)}
							inputProps={{
								name: "utilDebtNoLongerOccupy",
								id: "utilDebtNoLongerOccupy",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{((backRentOccupy && backRentOccupy == "yes") ||
					(utilDebtNoLongerOccupy && utilDebtNoLongerOccupy == "yes")) && (
					<Grid item xs={12}>
						<FormControl
							required
							fullWidth
							className={"MuiFormControl-marginNormal"}
							error={error && (!currentMonthlyRent || currentMonthlyRent == "")}
						>
							{currentMonthlyRent && currentMonthlyRent != "" ? null : (
								<InputLabel style={{ paddingLeft: 15 }} htmlFor="currentMonthlyRent">
									What is (or was) your monthly rent?
								</InputLabel>
							)}
							<TextField
								// disabled={!toaBackRent}
								id="currentMonthlyRent"
								name="currentMonthlyRent"
								label={"What is (or was) your monthly rent?"}
								fullWidth
								type={"number"}
								required
								value={currentMonthlyRent ? currentMonthlyRent : ""}
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								onChange={(e) => updateData(e.target.name, Math.abs(e.target.value))}
							/>
						</FormControl>
					</Grid>
				)}
				{((backRentOccupy && backRentOccupy == "yes") ||
					(utilDebtNoLongerOccupy && utilDebtNoLongerOccupy == "yes")) && (
					<Grid item xs={12}>
						<FormControl
							required
							fullWidth
							className={"MuiFormControl-marginNormal"}
							error={error && (!backRentAmount || backRentAmount == "")}
						>
							{backRentAmount && backRentAmount != "" ? null : (
								<InputLabel style={{ paddingLeft: 15 }} htmlFor="backRentAmount">
									How much total rent do you currently owe?
								</InputLabel>
							)}
							<TextField
								// disabled={!toaBackRent}
								id="backRentAmount"
								name="backRentAmount"
								label={"How much total rent do you currently owe?"}
								fullWidth
								type={"number"}
								required
								value={backRentAmount ? backRentAmount : ""}
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								onChange={(e) => updateData(e.target.name, Math.abs(e.target.value))}
							/>
						</FormControl>
					</Grid>
				)}

				{((backRentOccupy && backRentOccupy == "yes") ||
					(utilDebtNoLongerOccupy && utilDebtNoLongerOccupy == "yes")) && (
					<Grid item xs={12}>
						<FormControl
							fullWidth
							required
							className={"MuiFormControl-marginNormal"}
							style={{ marginTop: 0 }}
							error={error && (!backRentMonths || backRentMonths == "")}
						>
							<InputLabel shrink htmlFor="backRentMonths">
								Number of months behind on rent (whole numbers only)?
							</InputLabel>
							<TextField
								// disabled={!toaBackRent}
								id="backRentMonths"
								name="backRentMonths"
								label="Number of months behind on rent (whole numbers only)?"
								fullWidth
								required
								type={"number"}
								step={1}
								min={0}
								value={backRentMonths ? backRentMonths : ""}
								onChange={(e) => updateData(e.target.name, Math.round(Math.abs(e.target.value)))}
							/>
						</FormControl>
					</Grid>
				)}

				{((backRentOccupy && backRentOccupy == "yes") ||
					(utilDebtNoLongerOccupy && utilDebtNoLongerOccupy == "yes")) && (
					<Grid item xs={12}>
						<TextField
							name="assistanceTimePeriod"
							value={assistanceTimePeriod ? assistanceTimePeriod : ""}
							label="What time period (months and years) do you need rental assistance for?"
							fullWidth
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}

				{/* {((backRentOccupy && backRentOccupy == "yes") ||
					(utilDebtNoLongerOccupy && utilDebtNoLongerOccupy == "yes")) && (
					<Grid item xs={12}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							style={{ marginTop: 0 }}
							required
							error={error && (!deniedAssistanceCA || deniedAssistanceCA == "")}
						>
							<InputLabel shrink htmlFor="deniedAssistanceCA">
								Have you been denied assistance by the CA COVID-19 Rent Relief Program for any of
								these months?
							</InputLabel>
							<InputLabel htmlFor="deniedAssistanceCA">
								Have you been denied assistance by the CA COVID-19 Rent Relief Program for any of
								these months?
							</InputLabel>
							<Select
								native
								error={error && (!deniedAssistanceCA || deniedAssistanceCA == "")}
								value={deniedAssistanceCA}
								onChange={(evt) => updateData("deniedAssistanceCA", evt.target.value)}
								inputProps={{
									name: "deniedAssistanceCA",
									id: "deniedAssistanceCA",
								}}
							>
								<option aria-label="None" value="" />
								<option value={"yes"}>Yes</option>
								<option value={"no"}>No</option>
							</Select>
						</FormControl>
					</Grid>
				)} */}

				{/* <Grid
            item
            xs={12}
          >
            <FormControl
              fullWidth
              className={"MuiFormControl-marginNormal"}
              style={{marginTop: 0}}
              required
              error={
                error && (!unpaidUtilities || unpaidUtilities == "")
              }
            >
              <InputLabel shrink htmlFor="unpaidUtilities">
                Do you have unpaid utilities (For example, PG&E, SFPUC, Recology, internet)?
              </InputLabel>
              <InputLabel htmlFor="unpaidUtilities">
                Do you have unpaid utilities (For example, PG&E, SFPUC, Recology, internet)?
              </InputLabel>
              <Select
                native
                error={error && (!unpaidUtilities || unpaidUtilities == "")}
                value={unpaidUtilities}
                onChange={(evt) =>
                  updateData("unpaidUtilities", evt.target.value)
                }
                inputProps={{
                  name: "unpaidUtilities",
                  id: "unpaidUtilities",
                }}
              >
                <option aria-label="None" value="" />
                <option value={"yes"}>Yes</option>
                <option value={"no"}>No</option>
              </Select>
            </FormControl>
          </Grid> */}

				{/* <Grid
          item
          xs={12}
        >
          <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
            { backUtilityAmount && backUtilityAmount != "" ? null : <InputLabel style={{paddingLeft: 15}} htmlFor="backUtilityAmount">How much do you currently owe in back utility payments?</InputLabel> }
          <TextField
            // disabled={!toaBackRent}
            id="backUtilityAmount"
            name="backUtilityAmount"
            label={'How much do you currently owe in back utility payments?'}
            fullWidth
            type={'number'}
            value={backUtilityAmount ? backUtilityAmount : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
          </FormControl>
        </Grid> */}

				{/* <Grid
          item
          xs={12}
        >
            <FormControl
              fullWidth
              className={"MuiFormControl-marginNormal"}
              style={{marginTop: 0}}
            >
              <InputLabel shrink htmlFor="backUtilityMonths">
                Number of months behind on utilities?
              </InputLabel>
          <TextField
            // disabled={!toaBackRent}
            id="backUtilityMonths"
            name="backUtilityMonths"
            label="Number of months behind on utilities?"
            fullWidth
            type={'number'}
            value={backUtilityMonths ? backUtilityMonths : ""}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
          </FormControl>
        </Grid> */}

				{((backRentOccupy && backRentOccupy == "yes") ||
					(utilDebtNoLongerOccupy && utilDebtNoLongerOccupy == "yes")) && (
					<Grid item xs={12}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							required
							error={error && (!incurDebt || incurDebt == "")}
						>
							<InputLabel shrink htmlFor="incurDebt">
								Since March 2020 have you taken on any type of debt in order to pay rent?
								(Reimbursement for rent paid is not available through SF ERAP.)
							</InputLabel>
							<InputLabel htmlFor="incurDebt">
								Since March 2020 have you taken on any type of debt in order to pay rent?
								(Reimbursement for rent paid is not available through SF ERAP.)
							</InputLabel>
							<Select
								native
								error={error && (!incurDebt || incurDebt == "")}
								value={incurDebt}
								onChange={(evt) => {
									if (evt.target.value == "yes") updateData("incurDebt", evt.target.value);
									if (evt.target.value == "no") {
										updateMultiData({
											incurDebt: "no",
											incurDebtType: "",
											incurDebtMonthsYears: "",
										});
									}
								}}
								inputProps={{
									name: "incurDebt",
									id: "incurDebt",
								}}
							>
								<option aria-label="None" value="" />
								<option value={"yes"}>Yes</option>
								<option value={"no"}>No</option>
							</Select>
						</FormControl>
					</Grid>
				)}

				{incurDebt &&
					incurDebt == "yes" &&
					((backRentOccupy && backRentOccupy == "yes") ||
						(utilDebtNoLongerOccupy && utilDebtNoLongerOccupy == "yes")) && (
						<Grid item xs={12}>
							<FormControl
								fullWidth
								className={"MuiFormControl-marginNormal"}
								required
								error={
									error &&
									((incurDebt && incurDebt == "yes" && !incurDebtType) || incurDebtType == "")
								}
							>
								<InputLabel shrink htmlFor="incurDebtType">
									What type of debt?
								</InputLabel>
								<InputLabel htmlFor="incurDebtType">What type of debt?</InputLabel>
								<Select
									native
									value={incurDebtType}
									onChange={(evt) => updateData("incurDebtType", evt.target.value)}
									inputProps={{
										name: "incurDebtType",
										id: "incurDebtType",
									}}
								>
									<option aria-label="None" value="" />
									<option value={"Payday Loan"}>Payday Loan</option>
									<option value={"Credit Card"}>Credit Card</option>
									<option value={"Other"}>Other</option>
								</Select>
							</FormControl>
						</Grid>
					)}

				{incurDebt &&
					incurDebt == "yes" &&
					((backRentOccupy && backRentOccupy == "yes") ||
						(utilDebtNoLongerOccupy && utilDebtNoLongerOccupy == "yes")) && (
						<Grid item xs={12}>
							<FormControl
								fullWidth
								className={"MuiFormControl-marginNormal"}
								style={{ marginTop: 0 }}
								required
								error={error && (!howMuchDebt || howMuchDebt == "")}
							>
								<InputLabel shrink htmlFor="howMuchDebt">
									How much debt have you taken on to pay rent or utilities?
								</InputLabel>
								<InputLabel htmlFor="howMuchDebt">
									How much debt have you taken on to pay rent or utilities?
								</InputLabel>
								<Select
									native
									error={error && (!howMuchDebt || howMuchDebt == "")}
									value={howMuchDebt}
									onChange={(evt) => updateData("howMuchDebt", evt.target.value)}
									inputProps={{
										name: "howMuchDebt",
										id: "howMuchDebt",
									}}
								>
									<option aria-label="None" value="" />
									<option value={"$0-$1000"}>$0-$1000</option>
									<option value={"$1,001-$5,000"}>$1,001-$5,000</option>
									<option value={"$5,001-$10,000"}>$5,001-$10,000</option>
									<option value={"More than $10,000"}>More than $10,000</option>
								</Select>
							</FormControl>
						</Grid>
					)}

				{0 > 1 &&
					incurDebt &&
					incurDebt == "yes" &&
					((backRentOccupy && backRentOccupy == "yes") ||
						(utilDebtNoLongerOccupy && utilDebtNoLongerOccupy == "yes")) && (
						<Grid item xs={12}>
							<TextField
								required
								error={
									error &&
									(!incurDebtMonthsYears ||
										incurDebtMonthsYears == "" ||
										(incurDebtMonthsYears && incurDebtMonthsYears.trim() == ""))
								}
								id="incurDebtMonthsYears"
								name="incurDebtMonthsYears"
								value={incurDebtMonthsYears ? incurDebtMonthsYears : ""}
								label="Please list months/year in which debt was incurred and amount per month."
								fullWidth
								autoComplete="incurDebtMonthsYears"
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</Grid>
					)}

				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Landlord Information (for unit where back rent is owed)
					</Typography>
					<Typography variant="h6" gutterBottom style={{ color: "#666", fontSize: "1rem" }}>
						Please list the name and address where you make your rent checks payable to (i.e.,
						Landlord, Master Tenant, Person Who Charges You Rent, or Management Company).
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<TextField
						// error={error && (!llName || llName == "")}
						id="llName"
						name="llName"
						value={llName ? llName : ""}
						label="First and Last Name of Landlord, Master Tenant, Person Who Charges You Rent, or Management Company Contact"
						fullWidth
						autoComplete="llName"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						// error={error && (!landlordaddress1 || landlordaddress1 == "")}
						id="landlordaddress1"
						name="landlordaddress1"
						value={landlordaddress1 ? landlordaddress1 : ""}
						label="Landlord Address line 1"
						fullWidth
						autoComplete="address-line1"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="landlordaddress2"
						name="landlordaddress2"
						value={landlordaddress2 ? landlordaddress2 : ""}
						label="Landlord Address line 2"
						fullWidth
						autoComplete="address-line2"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						// error={error && (!landlordcity || landlordcity == "")}
						// id="landlordcity"
						name="landlordcity"
						value={landlordcity ? landlordcity : ""}
						label="Landlord City"
						fullWidth
						autoComplete="address-level2"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="landlordstate"
						name="landlordstate"
						// error={error && (!landlordstate || landlordstate == "")}
						value={landlordstate ? landlordstate : ""}
						label="Landlord State/Province/Region"
						onChange={(e) => updateData(e.target.name, e.target.value)}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						// error={error && (!landlordzip || landlordzip == "")}
						id="landlordzip"
						name="landlordzip"
						value={landlordzip ? landlordzip : ""}
						label="Landlord Zip / Postal code"
						fullWidth
						autoComplete="billing postal-code"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth

						// error={error && (!landlordPhone || landlordPhone == "")}
					>
						<InputLabel htmlFor="landlord-primary-phone">Landlord Primary Phone #</InputLabel>
						<Input
							id="landlord-primary-phone"
							aria-describedby="landlord-phone-helper-text"
							value={landlordPhone ? landlordPhone : ""}
							name={"landlordPhone"}
							onChange={(e) =>
								updateData(
									e.target.name,
									formatUSNumber(e.target.value ? e.target.value.toString() : e.target.value)
								)
							}
						/>
						<FormHelperText id="landlord-phone-helper-text">
							{/* Your email address. */}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						// error={error && (!landlordEmail || landlordEmail == "")}
						id="landlordEmail"
						name="landlordEmail"
						value={landlordEmail ? landlordEmail : ""}
						label="Landlord E-mail"
						fullWidth
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Move-in Assistance
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						style={{ marginTop: 0 }}
						required
						error={error && (!moveInAssistance || moveInAssistance == "")}
					>
						{/* <InputLabel shrink htmlFor="moveInAssistance">
							Are you applying for move-in assistance (deposit, first month’s rent, and/or last
							month’s rent) for a unit you have already identified, been approved for, and intend to
							lease?
						</InputLabel> */}
						<InputLabel htmlFor="moveInAssistance" style={{ marginTop: -30 }}>
							Are you applying for move-in assistance (deposit, first month’s rent, and/or last
							month’s rent) for a unit you have already identified, been approved for, and intend to
							lease?
						</InputLabel>
						<Select
							native
							error={error && (!moveInAssistance || moveInAssistance == "")}
							value={moveInAssistance}
							onChange={(evt) => updateData("moveInAssistance", evt.target.value)}
							inputProps={{
								name: "moveInAssistance",
								id: "moveInAssistance",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{moveInAssistance && moveInAssistance == "yes" && (
					<Typography variant="h6" gutterBottom style={{ color: "#666", fontSize: "1rem" }}>
						Only fill out the following information if you need "move-in" financial assistance (e.g.
						first or last month of rent or security deposit) for housing that you’ve already found
						and intend to rent. This program does not assist people with a housing search. Please
						fill out the following information to the best of your ability.
					</Typography>
				)}

				{/* {moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							style={{ marginTop: 0 }}
							required
							error={error && (!moveInAssistanceType || moveInAssistanceType == "")}
						>
							<InputLabel shrink htmlFor="moveInAssistanceType">
								What type of move-in assistance do you need? Select all that apply.
							</InputLabel>
							<InputLabel htmlFor="moveInAssistanceType">
								What type of move-in assistance do you need? Select all that apply.
							</InputLabel>
							<Select
								native
								error={error && (!moveInAssistanceType || moveInAssistanceType == "")}
								value={moveInAssistanceType}
								onChange={(evt) => updateData("moveInAssistanceType", evt.target.value)}
								inputProps={{
									name: "moveInAssistanceType",
									id: "moveInAssistanceType",
								}}
							>
								<option aria-label="None" value="" />
								<option value={"Security Deposit"}>Security Deposit</option>
								<option value={"First Month's Rent"}>First Month's Rent</option>
								<option value={"Last Month's Rent"}>Last Month's Rent</option>
								<option value={"Security Deposit & First Month's Rent"}>
									Security Deposit & First Month's Rent
								</option>
								<option value={"Security Deposit & First Month's Rent"}>
									Security Deposit & Last Month's Rent
								</option>
								<option value={"Security Deposit, First Month's Rent & Last Month's Rent"}>
									Security Deposit, First Month's Rent & Last Month's Rent
								</option>
								<option value={"First & Last Month's Rent"}>First & Last Month's Rent</option>
							</Select>
						</FormControl>
					</Grid>
				)} */}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Typography
						variant="h6"
						gutterBottom
						style={{
							textAlign: "left",
							color: "#666",
							fontSize: "1rem",
							paddingTop: 15,
							style: "bold",
							width: "100%",
							paddingLeft: "10px",
						}}
					>
						Address you are moving to
					</Typography>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12}>
						<Autocomplete
							onError={(e) => {
								console.log("autocomplete did error", e);
							}}
							options={suggestedAddress.address}
							getOptionLabel={(option) => {
								console.log("option", option);
								return option && option.value && option.inputValue
									? `${option.freeformAddress}`
									: `${option.toString()}`;
							}}
							value={moveInAddress1 ? moveInAddress1 : ""}
							onInputChange={(e, newValue) => {
								console.log("autocomplete on change", newValue, e, e ? e.target : "no target");
								setSelectedAddress(newValue);
								if (
									e &&
									e.target &&
									e.target.value &&
									e.target.value.length > 4 &&
									e.target.value.indexOf(" ") >= 0
								) {
									findAddress(e.target.value);
								} else {
									setSuggestedAddress({ address: [] });
								}
								if (e && e.target) {
									updateData(e.target.name, e.target.value);
								}
							}}
							clearOnBlur={false}
							onChange={(event, option) => {
								console.log("on auto did change", option);
								if (option && option.streetNumber)
									data.setFormData({
										...data.formData,
										moveInAddress1: `${option.streetNumber} ${option.streetName}`,
										moveInCity: option.localName,
										moveInZip: option.postalCode,
										moveInState: "CA",
									});
							}}
							renderOption={(option) => {
								console.log("render option", option);
								if (moveInAddress1 !== `${option.streetNumber} ${option.streeName}`) {
								}
								return <div>{`${option.freeformAddress}`}</div>;
							}}
							renderInput={(params) => {
								console.log("input params", params, suggestedAddress, selectedAddress);
								return (
									<TextField
										{...params}
										id="moveInAddress1"
										name="moveInAddress1"
										label="Address line 1"
										fullWidth
										required
										error={
											error &&
											(!moveInAddress1 ||
												moveInAddress1 == "" ||
												(moveInAddress1 && moveInAddress1.trim() == ""))
										}
										value={moveInAddress1}
									/>
								);
							}}
						/>
					</Grid>
				)}

				{/* {moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12}>
						<TextField
							id="moveInAddress1"
							name="moveInAddress1"
							value={moveInAddress1 ? moveInAddress1 : ""}
							label="Address line 1"
							fullWidth
							autoComplete="address-line1"
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)} */}
				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12}>
						<TextField
							id="moveInAddress2"
							name="moveInAddress2"
							value={moveInAddress2 ? moveInAddress2 : ""}
							label="Address line 2"
							fullWidth
							autoComplete="address-line2"
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}
				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<TextField
							// error={error && (!landlordcity || landlordcity == "")}
							// id="landlordcity"
							name="moveInCity"
							value={moveInCity ? moveInCity : ""}
							label="City"
							fullWidth
							autoComplete="address-city"
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}
				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<TextField
							id="moveInState"
							name="moveInState"
							// error={error && (!landlordstate || landlordstate == "")}
							value={moveInState ? moveInState : ""}
							label="State/Province/Region"
							onChange={(e) => updateData(e.target.name, e.target.value)}
							fullWidth
						/>
					</Grid>
				)}
				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<TextField
							// error={error && (!landlordzip || landlordzip == "")}
							id="moveInZip"
							name="moveInZip"
							value={moveInZip ? moveInZip : ""}
							label="Zip / Postal code"
							fullWidth
							autoComplete="billing postal-code"
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}

				{/* {moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							style={{ marginTop: 0 }}
							// error={
							//   error && (!housingTypeDestination || housingTypeDestination == "")
							// }
						>
							<InputLabel shrink htmlFor="housingTypeDestination">
								Type of Unit
							</InputLabel>
							<InputLabel htmlFor="housingTypeDestination">Type of Unit</InputLabel>
							<Select
								native
								error={error && (!housingTypeDestination || housingTypeDestination == "")}
								value={housingTypeDestination}
								onChange={(evt) => updateData("housingTypeDestination", evt.target.value)}
								inputProps={{
									name: "housingTypeDestination",
									id: "housingTypeDestination",
								}}
							>
								<option aria-label="None" value="" />
								<option
									value={
										"Rental unit with a rental subsidy, or an affordable/low-income rental unit"
									}
								>
									Rental unit with a rental subsidy, or an affordable/low-income rental unit
								</option>
								<option value={"Rental unit with no rental subsidy"}>
									Rental unit with no rental subsidy
								</option>
							</Select>
						</FormControl>
					</Grid>
				)} */}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12}>
						<Grid item xs={12}>
							<InputLabel htmlFor="unitMovingInto">
								{" "}
								Do any of the following apply to the unit you are moving into?
							</InputLabel>
							<Typography
								variant="h6"
								gutterBottom
								style={{ color: "#666", fontSize: "1rem", paddingTop: 5 }}
							>
								<List style={{ paddingLeft: 50 }}>
									<ListItem style={{ display: "inline" }}>
										• A program or agency will be paying all or part of my rent
									</ListItem>
									<br />
									<ListItem style={{ display: "inline" }}>
										• I am moving in with a housing voucher/Section 8
									</ListItem>
									<br />
									<ListItem style={{ display: "inline" }}>
										• I am moving into an affordable housing unit and/or got my housing through a
										lottery (DAHLIA)
									</ListItem>
									<br />
									<ListItem></ListItem>
								</List>
							</Typography>
						</Grid>

						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							required
							error={error && (!rentalSubsidyLowIncome || rentalSubsidyLowIncome == "")}
						>
							{/* <InputLabel shrink htmlFor="rentalSubsidyLowIncome">
								Do any of the following apply to your CURRENT housing situation?*
							</InputLabel> */}
							<InputLabel htmlFor="unitMovingInto">
								Do any of the above apply to the unit you are moving into?
							</InputLabel>
							<Select
								native
								error={error && (!unitMovingInto || unitMovingInto == "")}
								value={unitMovingInto}
								onChange={(evt) => {
									updateData("unitMovingInto", evt.target.value);
								}}
								inputProps={{
									name: "unitMovingInto",
									id: "unitMovingInto",
								}}
							>
								<option aria-label="None" value="" />
								<option value={"Yes"}>Yes</option>
								<option value={"No"}>No</option>
								<option value={"Not sure"}>Not sure</option>
							</Select>
						</FormControl>
					</Grid>
				)}

				{unitMovingInto && (unitMovingInto == "Yes" || unitMovingInto == "Not sure") && (
					<Grid item xs={12} sm={6}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							required
							error={
								error &&
								((((unitMovingInto && unitMovingInto == "Yes") ||
									(unitMovingInto && unitMovingInto == "Not sure")) &&
									!typeOfSubsidyMovingInto) ||
									typeOfSubsidyMovingInto == "")
							}
						>
							<InputLabel shrink htmlFor="typeOfSubsidyMovingInto">
								Please specify the type of housing program you are in.
							</InputLabel>
							<InputLabel htmlFor="typeOfSubsidyMovingInto">
								Please specify the type of housing program you are in.
							</InputLabel>
							<Select
								native
								value={typeOfSubsidyMovingInto}
								onChange={(evt) => updateData("typeOfSubsidyMovingInto", evt.target.value)}
								inputProps={{
									name: "typeOfSubsidyMovingInto",
									id: "typeOfSubsidyMovingInto",
								}}
							>
								<option aria-label="None" value="" />
								<option value={"Below Market Rate Unit (Inclusionary) via DAHLIA housing lottery"}>
									Below Market Rate Unit (Inclusionary) via DAHLIA housing lottery
								</option>
								<option value={"100% Affordable Unit via DAHLIA housing lottery"}>
									100% Affordable Unit via DAHLIA housing lottery
								</option>
								<option
									value={
										"Permanent Supportive Housing (housing for people who used to be homeless)"
									}
								>
									Permanent Supportive Housing (housing for people who used to be homeless)
								</option>
								<option value={"Small/Big Sites Program Unit"}>Small/Big Sites Program Unit</option>
								<option value={"Housing Choice Voucher (Section 8)"}>
									Housing Choice Voucher (Section 8)
								</option>
								<option value={"Rapid Rehousing (monthly rental assistance program)"}>
									Rapid Rehousing (monthly rental assistance program)
								</option>
								<option value={"Public Housing (San Francisco Housing Authority)"}>
									Public Housing (San Francisco Housing Authority)
								</option>
								<option value={"HOPE SF"}>HOPE SF</option>
								<option value={"RAD"}>RAD</option>
								<option value={"Veteran's Housing"}>Veteran's Housing</option>
								<option value={"Co-op"}>Co-op</option>
								<option value={"Other Tenant-Based Subsidy/Voucher"}>
									Other Tenant-Based Subsidy/Voucher
								</option>
								<option value={"I don't know"}>I don't know</option>
							</Select>
						</FormControl>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								margin="normal"
								id="moveInDate"
								label="Move-in date"
								invalidDateMessage={
									<span style={{ color: "red" }}>Invalid Date - Please enter as MM/DD/YYYY</span>
								}
								fullWidth
								name={"moveInDate"}
								value={moveInDate ? moveInDate : null}
								onChange={(date, value) => {
									console.log("value", value);
									updateData("moveInDate", value);
								}}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
							{Security_x0020_Deposit && Security_x0020_Deposit != "" ? null : (
								<InputLabel style={{ paddingLeft: 15 }} htmlFor="Security_x0020_Deposit">
									Security deposit amount
								</InputLabel>
							)}
							<TextField
								// disabled={!toaBackRent}
								id="Security_x0020_Deposit"
								name="Security_x0020_Deposit"
								label={"Security deposit amount"}
								fullWidth
								type={"number"}
								value={Security_x0020_Deposit ? Security_x0020_Deposit : ""}
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
							{First_x0020_Month_x0020_Rent && First_x0020_Month_x0020_Rent != "" ? null : (
								<InputLabel style={{ paddingLeft: 15 }} htmlFor="First_x0020_Month_x0020_Rent">
									First month's rent amount
								</InputLabel>
							)}
							<TextField
								// disabled={!toaBackRent}
								id="First_x0020_Month_x0020_Rent"
								name="First_x0020_Month_x0020_Rent"
								label={"First month's rent amount"}
								fullWidth
								type={"number"}
								value={First_x0020_Month_x0020_Rent ? First_x0020_Month_x0020_Rent : ""}
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
							{Last_x0020_Month_x0020_Rent && Last_x0020_Month_x0020_Rent != "" ? null : (
								<InputLabel style={{ paddingLeft: 15 }} htmlFor="Last_x0020_Month_x0020_Rent">
									Last month's rent amount
								</InputLabel>
							)}
							<TextField
								// disabled={!toaBackRent}
								id="Last_x0020_Month_x0020_Rent"
								name="Last_x0020_Month_x0020_Rent"
								label={"Last month's rent amount"}
								fullWidth
								type={"number"}
								value={Last_x0020_Month_x0020_Rent ? Last_x0020_Month_x0020_Rent : ""}
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								margin="normal"
								id="datePaymentDue"
								label="Date payment is due"
								invalidDateMessage={
									<span style={{ color: "red" }}>Invalid Date - Please enter as MM/DD/YYYY</span>
								}
								fullWidth
								name={"datePaymentDue"}
								value={datePaymentDue ? datePaymentDue : null}
								onChange={(date, value) => {
									console.log("value", value);
									updateData("datePaymentDue", value);
								}}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Typography
						variant="h6"
						gutterBottom
						style={{
							color: "#666",
							fontSize: "1rem",
							paddingTop: 15,
							style: "bold",
							width: "100%",
						}}
					>
						Landlord, Master Tenant, Person Who Charges You Rent, or Management Company Information
						(for unit you are moving in to)
					</Typography>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12}>
						<TextField
							id="moveInLandlordName"
							name="moveInLandlordName"
							value={moveInLandlordName ? moveInLandlordName : ""}
							label="First and Last Name of Landlord, Master Tenant, Person Who Charges You Rent, or Management Company Contact"
							fullWidth
							autoComplete="landlord-name"
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12}>
						<TextField
							id="moveInLandlordAddress1"
							name="moveInLandlordAddress1"
							value={moveInLandlordAddress1 ? moveInLandlordAddress1 : ""}
							label="Address line 1"
							fullWidth
							autoComplete="address-line1"
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12}>
						<TextField
							id="moveInLandlordAddress2"
							name="moveInLandlordAddress2"
							value={moveInLandlordAddress2 ? moveInLandlordAddress2 : ""}
							label="Address line 2"
							fullWidth
							autoComplete="address-line2"
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<TextField
							// error={error && (!landlordcity || landlordcity == "")}
							// id="landlordcity"
							name="moveInLandlordCity"
							value={moveInLandlordCity ? moveInLandlordCity : ""}
							label="City"
							fullWidth
							autoComplete="address-city"
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<TextField
							id="moveInLandlordState"
							name="moveInLandlordState"
							// error={error && (!landlordstate || landlordstate == "")}
							value={moveInLandlordState ? moveInLandlordState : ""}
							label="State/Province/Region"
							onChange={(e) => updateData(e.target.name, e.target.value)}
							fullWidth
						/>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<TextField
							// error={error && (!landlordzip || landlordzip == "")}
							id="moveInLandlordZip"
							name="moveInLandlordZip"
							value={moveInLandlordZip ? moveInLandlordZip : ""}
							label="Zip / Postal code"
							fullWidth
							autoComplete="billing postal-code"
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel htmlFor="landlord-primary-phone2">Landlord Primary Phone #</InputLabel>
							<Input
								id="landlord-primary-phone2"
								aria-describedby="landlord-phone-helper-text"
								value={moveInLandlordPhone ? moveInLandlordPhone : ""}
								name={"moveInLandlordPhone"}
								onChange={(e) =>
									updateData(
										e.target.name,
										formatUSNumber(e.target.value ? e.target.value.toString() : e.target.value)
									)
								}
							/>
							<FormHelperText id="landlord-phone-helper-text">
								{/* Your email address. */}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}

				{moveInAssistance && moveInAssistance == "yes" && (
					<Grid item xs={12} sm={6}>
						<TextField
							id="moveInLandlordEmail"
							name="moveInLandlordEmail"
							value={moveInLandlordEmail ? moveInLandlordEmail : ""}
							label="Landlord E-mail"
							fullWidth
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				)}
			</Grid>
		</React.Fragment>
	);
}
